import { Component, inject, LOCALE_ID } from "@angular/core";
import { AbstractModal } from "src/app/shared/components/modal/abstractModal";
import { Utils } from "src/app/shared/shared";
import * as converters from "../converters";

@Component({
  selector: "electrolyser-modal",
  templateUrl: "./modal.component.html",
  standalone: false,
})
export class ElectrolyserModalComponent extends AbstractModal {
  public readonly locale = inject(LOCALE_ID);

  public readonly CONVERT_TO_WATT = Utils.CONVERT_TO_WATT;
  public readonly CONVERT_ELECTROLYSER_STATE = converters.CONVERT_ELECTROLYSER_STATE(this.translate);
  public readonly CONVERT_TO_LITERS_PER_HOUR = converters.CONVERT_TO_LITERS_PER_HOUR;
  public readonly CONVERT_TO_LITERS = converters.CONVERT_TO_LITERS;
  public readonly CONVERT_SECONDS_TO_DURATION_FORMAT = converters.CONVERT_SECONDS_TO_DURATION_FORMAT(this.locale);
  public readonly CONVERT_TO_BAR = converters.CONVERT_TO_BAR;
  public readonly CONVERT_TO_AMPERE = converters.CONVERT_TO_AMPERE;
  public readonly CONVERT_TO_VOLT = converters.CONVERT_TO_VOLT;
  public readonly CONVERT_TO_DEGREE_CELSIUS = converters.CONVERT_TO_DEGREE_CELSIUS;
}
